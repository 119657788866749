<template>
  <div>
    <v-btn
      class="info font-weight-bold"
      :disabled="btn_disable"
      @click="getShopSiteList"
      ><v-icon left>mdi-cloud-download-outline</v-icon
      ><span class="d-none d-sm-flex">スケジュール取込</span
      ><span class="d-sm-none">取込</span></v-btn
    >
    <!-- スケジュール取込ダイアログ -->
    <v-dialog v-model="dialog" scrollable persistent width="1000px">
      <v-card :loading="disabled" :disabled="disabled">
        <v-card-title>
          <span class="text-h5">スケジュール取込</span>
        </v-card-title>
        <v-card-text>
          <h3 id="cast_sync_step1">取込サイト選択</h3>
          <div class="mt-2 mb-2">
            一週間分のスケジュールを取り込む媒体を選択してください。現在入力されているスケジュールは上書きされます。
            また、取り込んだスケジュールで週間パターンを更新出来ます。
          </div>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-select
                  prepend-icon="mdi-web"
                  v-model="shopSiteId"
                  :items="shopSites"
                  item-value="id"
                  item-text="name"
                  no-data-text="更新管理にて媒体を登録して下さい"
                  label="媒体選択"
                  success
                  outlined
                ></v-select>
              </v-col>
            </v-row>
          </v-container>
          <h3 id="cast_sync_step1">取込オプション：週間パターンの更新</h3>
          <div class="mt-2 mb-2">
            こちらにチェックを付けると、取り込んだスケジュールで週間パターンを作成・更新します。また、週間パターン更新の際、AUTO設定のON・OFFを指定できます。
          </div>
          <v-container>
            <v-row>
              <v-col cols="12" sm="6">
                <v-checkbox
                  v-model="updatePattern"
                  label="取り込んだスケジュールで週間パターンを更新"
                ></v-checkbox>
              </v-col>
              <v-col cols="12" sm="6">
                <v-switch
                  v-model="is_auto"
                  :disabled="!updatePattern"
                  :label="`${is_auto ? 'AUTO設定ON' : 'AUTO設定OFF'}`"
                ></v-switch>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-container>
            <v-row justify="end">
              <v-col cols="6" sm="3">
                <general-button
                  btn_color="gray"
                  btn_block
                  @click-event="cancelDialog"
                  ><template v-slot:icon
                    ><v-icon left>mdi-close-circle</v-icon></template
                  >キャンセル</general-button
                >
              </v-col>
              <v-col cols="6" sm="3">
                <general-button
                  btn_type="info"
                  btn_block
                  :btn_disabled="btn_disable"
                  @click-event="onSubmit"
                  ><template v-slot:icon
                    ><v-icon left>mdi-cloud-download-outline</v-icon></template
                  >取込</general-button
                >
              </v-col>
            </v-row>
          </v-container>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { reactive, toRefs, defineComponent } from "@vue/composition-api";
import store from "@/store/index.js";
import setting from "@/common/setting.js";
import utilDate from "@/common/utilDate.js";
import { repositoryFactory } from "@/repository/repositoryFactory";
import { useRouter } from "@/utils";

export default defineComponent({
  props: ["btn_disable"],
  setup(props, ctx) {
    const { router } = useRouter();

    const SynchroRepository = repositoryFactory.get("synchro");
    const ShopSitesRepository = repositoryFactory.get("shopSites");
    const castsRepository = repositoryFactory.get("casts");

    const state = reactive({
      dialog: false, // ダイアログ表示用
      shopSites: [],
      shopSiteId: 0,
      defaultShopSiteId: 0,
      disabled: false,
      updatePattern: false,
      is_auto: false,
    });

    // 初期処理
    const init = async () => {
      state.updatePattern = false;
      state.is_auto = false;
    };

    init();

    const getShopSiteList = async () => {
      state.dialog = true;
      // ローディング表示
      store.dispatch("loadingIcon/showIcon");
      state.disabled = true;

      // 更新サイト一覧取得
      state.shopSites.splice(0);
      const params = new URLSearchParams();
      params.append("shop_id", store.getters["shops/currentShop"].id);
      await ShopSitesRepository.list_getschedule(params)
        .then((response) => {
          if (response.data) {
            Object.keys(response.data).forEach(function (key) {
              state.shopSites.push({
                "id": response.data[key].id,
                "name":
                  response.data[key].sites_name +
                  (JSON.parse(response.data[key].config).memo != ""
                    ? " [ " + JSON.parse(response.data[key].config).memo + " ] "
                    : ""),
              });
            });
            if (state.shopSites.length > 0) {
              state.shopSiteId = state.shopSites[0].id;
            }
          }
        })
        .catch((error) => {
          throw "ERROR:データ取得エラー (" + error + ")";
        });

      // ローディング非表示
      store.dispatch("loadingIcon/hideIcon");
      state.disabled = false;
    };

    // スケジュール取込処理
    const onSubmit = async () => {
      if (
        !window.confirm(
          "選択したサイトから全キャストのスケジュールを一週間取り込みます。よろしいですか？\n（現在、登録されているスケジュールは上書きされます）"
        )
      ) {
        return false;
      }
      // ローディング表示
      store.dispatch("loadingIcon/showIcon");
      state.disabled = true;

      // スケジュール取込処理
      let result = false;
      const current_day = store.getters["schedules/getCurrentDay"];
      const shop_dateline = store.getters["shops/dateline"];
      let params = {
        shop_id: store.getters["shops/currentShop"]["id"],
        work_date: utilDate.getDateFormatLong(
          utilDate.getToday(current_day, shop_dateline)
        ),
        shop_site_id: state.shopSiteId,
        pattern_set_flg: state.updatePattern,
        auto_on_flg: state.is_auto,
      };
      result = await SynchroRepository.get_schedule(params)
        .then((response) => {
          if (response.data) {
            if (response.data.status) {
              return true;
            }
          } else {
            alert(response.data.message);
            return false;
          }
        })
        .catch((error) => {
          throw (
            "ERROR:Schedule/Synchro/SiteGetSchedule.vue/onSubmit SynchroRepository.get_schedule (" +
            error +
            ")"
          );
        });

      // ローディング非表示
      store.dispatch("loadingIcon/hideIcon");
      state.disabled = false;

      // ダイアログ閉じる
      state.dialog = false;

      if (result) {
        alert("スケジュールの取り込みが完了しました。");
        //ctx.emit("getSchedule");
        router.go({ path: router.currentRoute.path, force: true });
      } else {
        alert("スケジュールの取り込みに失敗しました。");
      }
    };

    // ダイアログキャンセルボタン
    const cancelDialog = () => {
      state.dialog = false;
    };

    return {
      props,
      ...toRefs(state),
      onSubmit,
      cancelDialog,
      getShopSiteList,
    };
  },
});
</script>
